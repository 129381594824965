import React, { useState } from 'react';
import { Space, Table } from 'antd';
import { MailFilled } from '@ant-design/icons';
import { confirmAlert } from 'react-confirm-alert';
import parse from 'html-react-parser';

import { useContacts, useContactUpdate, useUnreadContactsCounter } from '../../../../../hooks';
import { formatDatetime } from '../../../../../utils/dateTime';

import { RoundTag } from '../../../../../common-styles';
import { Contact } from '../../../../../models/DataResponse';
import { TableEditAction } from '../../../../../components/TableEditAction';
import { TableGenericAction } from '../../../../../components/TableGenericAction';
import { ProcessEditor } from '../ProcessEditor';
import { NotificationDispatcher, NotificationType } from '../../../../../components/Notification';
import { TableDeleteAction } from '../../../../../components/TableDeleteAction';

export const ContactsTable = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  const { refetch: refetchUnreaderCounter } = useUnreadContactsCounter();
  const { deleteContact, update } = useContactUpdate();
  const { data: contacts, isFetching, refetch: refetchContacts } = useContacts(1);

  // useEffect(() => {
  //   const intersectionObserver = new IntersectionObserver((entries) => {
  //     if (entries.some((entry) => entry.isIntersecting)) {
  //       setCurrentPage((prevPage) => prevPage + 1);
  //     }
  //   });

  //   const element = document.querySelector('#lastInvisibleRow');
  //   if (element) {
  //     intersectionObserver.observe(element);
  //   }

  //   return () => intersectionObserver.disconnect();
  // }, []);

  const refreshData = () => {
    refetchContacts();
    refetchUnreaderCounter();
  }

  const handleUpdateContact = (id: string) => {
    confirmAlert({
      title: 'Atenção',
      message: `Tem certeza que deseja marcar mensagem como lida?`,
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            update(
              {
                _id: id,
                isRead: true,
              },
              {
                onSuccess: () => {
                  NotificationDispatcher({
                    message: 'Operação realizada com sucesso',
                  });
                  refreshData();
                },
                onError: () => {
                  NotificationDispatcher({
                    message: 'Erro ao tentar atualizar',
                    type: NotificationType.ERROR,
                  });
                }
              }
            );
          },
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
    });
  };

  const handleDeleteAction = (id: string) => {
    deleteContact(
      id,
      {
        onSuccess: () => {
          NotificationDispatcher({
            message: 'Contato removido com sucesso',
          });
          refreshData();
        }
      }
    );
  };

  const columns = [
    {
      title: 'Status',
      render: (_: any, contact: Contact) =>
        <RoundTag color={contact.isRead ? 'green' : 'red'}>
          {contact.isRead ? 'Lido' : 'Novo'}
        </RoundTag>
    },
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
    },
    {
      title: 'Mensagem',
      dataIndex: 'message',
    },
    {
      title: 'Recebido em',
      render: (_: any, contact: Contact) => formatDatetime(contact.createdAt)
    },
    {
      title: 'Atualizado em',
      render: (_: any, contact: Contact) => contact.updatedAt && formatDatetime(contact.updatedAt)
    },
    {
      title: 'Processo',
      render: (_: any, contact: Contact) => contact.processDescription && parse(contact.processDescription)
    },
    {
      title: 'Ação',
      render: (_: any, contact: Contact) => {
        return <Space direction="vertical" key={contact._id}>
          <TableEditAction onClick={() => setSelectedContact(contact)} />

          {!contact.isRead && (<TableGenericAction
            icon={<MailFilled style={{ fontSize: '18px' }} />}
            label="Mark as read"
            onClick={() => handleUpdateContact(contact._id)}
          />)}

          <TableDeleteAction
            onClick={() => handleDeleteAction(contact._id)}
            title={
              <span>
                Tem certeza que deseja excluir este contato {contact._id}?
              </span>
            }
          />
        </Space>
      }
    },
  ];

  return (
    <>
      {selectedContact &&
        <ProcessEditor
          contact={selectedContact}
          onClose={() => {
            setSelectedContact(null);
            refreshData();
          }}
        />
      }

      <Table
        bordered
        columns={columns}
        dataSource={contacts ? [...contacts] : []}
        loading={isFetching}
        pagination={false}
        scroll={{ x: 1300 }}
      />

      <div id="lastInvisibleRow" />
    </>
  );
}
