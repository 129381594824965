import React from "react";
import { toJS } from 'mobx';
import { Separator } from "@/common-styles";
import styled from 'styled-components';
import { CurrentOrder } from "../../CurrentOrder";
import { ProductStore } from "@/hooks/ProductStore/productsStore";
import { ProductMenu } from "@/models/ProductCatalog";
import { SellsConfiguration } from "@/models/SellsConfiguration";
import { CheckoutOrderButton } from "../CheckoutOrderButton";
import { SummaryLabel } from "../../styles";

interface OrderSummaryProps {
  isCountByProduct: boolean;
  handleDeleteAction: (product: ProductMenu) => void;
  handleEditAction: (isUpdate: boolean, product: ProductMenu) => void;
  sellsConfiguration: SellsConfiguration;
  onCheckout: () => void;
}

export const OrderSummary = ({ isCountByProduct, handleDeleteAction, handleEditAction, sellsConfiguration, onCheckout }: OrderSummaryProps) => {
  const products = toJS(ProductStore.products);

  return (
    <ResumeOrderContainer>
      <SummaryLabel>Meu Pedido</SummaryLabel>

      <Separator />

      <CurrentOrder
        hideTotal
        products={products}
        sellsConfiguration={sellsConfiguration}
        handleDeleteAction={handleDeleteAction}
        handleEditAction={handleEditAction}
      />

      <CheckoutOrderButton
        isCountByProduct={isCountByProduct}
        currency={sellsConfiguration.currency}
        onClick={onCheckout}
      />
    </ResumeOrderContainer>
  );
};

const ResumeOrderContainer = styled.div`
  padding: 10px;
  justify-content: start;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;

  background-color: white;

  border: 1px solid #eeee;
  border-radius: 20px;
`;
