import React, { useMemo, useState } from 'react';
import { ModalHeadLess } from '../../../../../components/ModalHeadless';
import { CompanyFeatures } from '../../../../../models/CompanyFeatures';
import { Container, OkButton, Title, Subtitle } from './styles';
import { getClosedReason } from '../../ScheduleUtils';

interface ClosedStoreProps {
  companyFeatures: CompanyFeatures;
  dismissPage(): void;
};

export const ClosedStore = ({
  companyFeatures,
  dismissPage,
}: ClosedStoreProps) => {
  const [isOpen, setOpen] = useState(true);
  const { openingHours, isEstablishmentOpen } = companyFeatures

  const handleClosePage = () => {
    setOpen(false);
    dismissPage();
  };

  const titlePage = useMemo(() => {
    const closedReason = getClosedReason(openingHours);

    if (!isEstablishmentOpen || closedReason === null) {
      return 'Loja fechada';
    } else {
      return `Aceitamos pedidos a partir das ${closedReason}`;
    }
  }, [openingHours, isEstablishmentOpen]);

  return (
    <ModalHeadLess isOpen={isOpen} toggle={handleClosePage} bgModal={'white'}>
      <Container>
        <Title>{titlePage}</Title>
        <Subtitle>
          Continue a verificar os nossos produtos e retorne quando a loja
          estiver aberta.
        </Subtitle>

        <OkButton onClick={handleClosePage}>
          <div>
            <span>Ok</span>
          </div>
        </OkButton>
      </Container>
    </ModalHeadLess>
  );
};

