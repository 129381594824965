import React from 'react';
import { Container } from './style';
import { FeedbackConfig } from '../../models/DataResponse';

interface FeedbackRateButtonProps {
  customColor?: string;
  feedbackConfig?: FeedbackConfig;
  onClick: () => void;
}

export const FeedbackRateButton = ({ customColor, feedbackConfig, onClick }: FeedbackRateButtonProps) => {
  if (feedbackConfig?.isEnabled === false) {
    return null;
  }

  const handleFeedbackClick = () => {
    if (feedbackConfig?.customPage) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = feedbackConfig.customPage;
      a.click();
    } else {
      onClick();
    }
  };

  return (
    <Container onClick={handleFeedbackClick} color={customColor}>
      <i className="fa fa-star" aria-hidden="true"></i>
      <p>Avalie</p>
    </Container>
  );
};
