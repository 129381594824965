import React, { useState } from 'react';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { isMobile } from '../../../../../utils/Util';
import { WeekdaysList } from '../../../../../utils/Constants';
import { OpeningHours } from '../../../../../models/DataResponse';

import { PageTitle } from '../../../../../common-styles';
import { ScheduleItem } from './styles';
import { getScheduleItems } from '../../ScheduleUtils';

interface AboutDetailsProps {
  openingHours: OpeningHours[];
  dismissPage(): void;
};

export const AboutDetails = ({
  openingHours,
  dismissPage,
}: AboutDetailsProps) => {
  const [isOpen, setOpen] = useState(true);

  const scheduleGroup = getScheduleItems(openingHours);
  const isMobileDevice = isMobile.any();

  const handleClosePage = () => {
    setOpen(false);
    dismissPage();
  };

  const isToday = (day: string) => {
    const today = new Date().getDay();
    return WeekdaysList[today] === day;
  };

  return (
    <Drawer
      closable={false}
      destroyOnClose={true}
      height={isMobileDevice ? '100%' : undefined}
      open={isOpen}
      placement={isMobileDevice ? 'bottom' : 'right'}
      size='large'
    >
      <CloseOutlined style={{ fontSize: '18px', fontWeight: 'bolder' }} onClick={handleClosePage} />
      <PageTitle style={{ marginTop: '16px' }}>Horário</PageTitle>
      {scheduleGroup.map((schedule) => {
        return (
          <ScheduleItem>
            <div className="details-schedule__day-title">
              <span
                className={`details-schedule__day-title-text ${isToday(schedule.weekdayText) && 'schedule-today'
                  }`}
              >
                {schedule.weekdayText}
              </span>
            </div>
            <span
              className={`details-schedule__day-title-text ${isToday(schedule.weekdayText) && 'schedule-today'
                }`}
            >
              {schedule.hoursText}
            </span>
          </ScheduleItem>
        );
      })}
    </Drawer>
  );
};
