import styled from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isMobile',
}) <ContainerProps>`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  position: ${(props) => (props.isMobile ? 'fixed' : 'relative')};
  bottom: 0;
  z-index: 999 !important;
  overflow: hidden;
  height: 88px;
  width: 100%;
  background: white;

  .button-container {
    display: flex;  
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .label {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: white;
    margin-left: 20px;
  }

  #adopt-controller-button {
    display: none !important;
  }
`;
