import Big from 'big.js';
import { Order } from '../models/DataResponse';
import { ComplementItemOption, ProductMenu } from '../models/ProductCatalog';
import { ExtraFee } from '../models/SellsConfiguration';
import formatCurrency from './formatCurrency';
import { sanitizePrice } from './priceUtil';

/**@deprecated To use hook useProductCost instead */
export const calculateTotalPrice = (products: ProductMenu[]): number => {
  let amount = 0;

  products.forEach((product) => {
    let computedOptions = [] as ComplementItemOption[];
    let complementAmount = 0;

    product.complements?.forEach((complement) =>
      complement.complementItemOptions.forEach((option) =>
        computedOptions.push(option)
      )
    );

    computedOptions
      .filter(
        (product) => product.counter !== undefined && product.counter !== 0
      )
      .forEach(
        (option) => (complementAmount += option.price * option.counter)
      );

    let productPrice = product.price;

    if (
      !productPrice ||
      productPrice === undefined ||
      isNaN(Number(sanitizePrice(productPrice)))
    ) {
      productPrice = '0';
    }

    const unitPrice = Number(sanitizePrice(productPrice)) + complementAmount;
    amount += unitPrice * product.counter;
  });

  return amount;
}

/**@deprecated */
export const formattedTotalPrice = (currency: string, value: number) => {
  return formatCurrency(value, currency);
}

/**@deprecated to be replaced by useProductCost hook*/
export const getTotalAndSubtotal = (order: Order, extraFees: ExtraFee[] = []) => {
  const { coupon, clientFare: deliveryFee, products } = order;

  const subtotalBig = new Big(calculateTotalPrice(products));
  let totalValueBig = subtotalBig;

  if (coupon) {
    const isPercentage = Number(coupon.discountType) === 0;

    if (isPercentage) {
      const discount = totalValueBig.times(coupon.discountValue).div(100);
      totalValueBig = totalValueBig.minus(discount);
    } else {
      totalValueBig = totalValueBig.minus(coupon.discountValue);
    }
  }

  if (deliveryFee) {
    totalValueBig = totalValueBig.plus(deliveryFee.price);
  }

  if (extraFees) {
    const fees = extraFees.filter(item => item.isEnabled)
      .reduce((prevAmount, currentValue) =>
        new Big(prevAmount).plus(currentValue.value),
        new Big(0));
    totalValueBig = totalValueBig.plus(fees);
  }

  return {
    subtotal: parseFloat(subtotalBig.toString()),
    total: parseFloat(totalValueBig.toString()),
  }
}