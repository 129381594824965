import React from 'react';
import { Box } from '@material-ui/core';

import {
  Container,
  Controllers,
  Title,
  TitleContainer,
} from './styles';

type BoxWithLabelProps = {
  title?: string;
  showContent?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

/** @deprecated */
const BoxWithLabel: React.FC<BoxWithLabelProps> = ({
  showContent,
  title,
  style,
  children,
}) => (
  <Container style={{...style, marginBottom: '14px'}}>
    <Box
      component="span"
      display="block"
      justifyContent="center"
      p={1}
      border={1}
      borderColor="#e4e4e4"
      width="100%"
    >
      <TitleContainer>
        {title && <Title>{title}</Title>}
      </TitleContainer>

      <Controllers id="childrenContainer">{children}</Controllers>
    </Box>
  </Container>
);

export default BoxWithLabel;
