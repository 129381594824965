
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageNotFound } from "../components/PageNotFound";
import OrderDetailsStaff from "../pages/backoffice/OrderDetailsStaff";
import { DynamicMenu } from "../pages/clients/MenuOnline";
import { CustomersPage } from "../pages/public/Customers";
import { DemoPage } from "../pages/public/Demo";
import { OrderStatusPage } from "../pages/public/OrderStatus";
import { QRLinksPage } from "../pages/public/QRLinks";
import { SessionPage } from "../pages/backoffice/Auth";
import OpenTestMenus from '../pages/public/Demo/test-menus';
import { Rodeadouro } from "../pages/clients/Rodeadouro";
import { DefineNewPassword } from "@/pages/backoffice/Auth/components/DefineNewPassword";

export const General = () => {
  const isDefaultPlatform = process.env.REACT_APP_ENV_TYPE === "QRCODE_PREFERIDO";

  return (
    <Routes>
      <Route element={<SessionPage />} path="/session" />
      <Route element={<Navigate to="/session#login" />} path="/login" />
      <Route element={<Navigate to="/session#signup" />} path="/signup" />
      <Route element={<Navigate to="/session#forgotpassword" />} path="/forgotpassword" />
      <Route element={<Navigate to="/session#login" />} path="/sysadmin" />
      <Route element={<Navigate to="/session#login" />} path="/sysadmin/home" />
      <Route element={<DefineNewPassword />} path="/confirm-email" />
      <Route element={<DefineNewPassword />} path="/reset-password" />

      <Route element={<OrderDetailsStaff />} path="/sysadmin/order-detail" />

      <Route element={<DynamicMenu />} path="/:menu" />
      <Route element={<QRLinksPage />} path="/qrlinks/:slug" />
      <Route element={<OrderStatusPage />} path="/order-status/:slug/:order" />
      <Route element={<PageNotFound />} path="/404" />

      {isDefaultPlatform && (
        <>
          {/* Static Clients */}
          <Route element={<Rodeadouro />} path="/rodeadouro" />

          {/* QRCode Preferido */}
          <Route element={<DemoPage />} path="/demo/" />
          <Route element={<CustomersPage />} path="/clientes/" />
          <Route element={<OpenTestMenus />} path="/all-menus/" />
        </>
      )}

      <Route element={<Navigate to="/clientes" />} path="/" />
    </Routes>
  );
};