import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import { toJS } from 'mobx';
import { confirmAlert } from 'react-confirm-alert';
import { ProductStore } from '../../../../../hooks/ProductStore/productsStore';

import { TextField } from '@material-ui/core';
import BoxWithLabel from '../../../../../components/BoxWithLabel';
import { CouponInput } from './components/CouponInput';

import { ClientOrderInformation } from './ClientOrderInformation';
import { ClosedStore } from '../ClosedStore';
import { CurrentOrder } from './CurrentOrder';
import { PaymentWay } from './PaymentWay';

import { ErrorDialog } from './components/ErrorDialog';
import { NotificationDispatcher, NotificationType } from '../../../../../components/Notification';
import { PaymentCardIframe } from './components/PaymentCardIframe';
import { PaymentLoadingModal } from '../../../../../components/PaymentLoadingModal';
import { ProductsNotAvailableModal } from './components/ProductsNotAvailable';

import { CompanyFeatures } from '../../../../../models/CompanyFeatures';

import {
  Address,
  Order,
  TaxData,
} from '../../../../../models/DataResponse';
import { ProductMenu } from '../../../../../models/ProductCatalog';
import { Coupon } from '../../../../../models/Coupon';
import { EasypayPaymentResponse } from './models/EasypayPayment';
import { ProductOutOfStockResponse, ProductsCannotBeSold } from '../../../../../models/ProductStock';
import { DeliveryFee } from '../../../../../models/DeliveryFee';

import { formattedTotalPrice, getTotalAndSubtotal } from '../../../../../utils/ProductUtil';
import { CARD_PAYMENT_TYPE, CASH_MONEY, MBWAY_PAYMENT_TYPE } from '../../../../../utils/Constants';
import { DeliveryType } from '../../../../../utils/DeliveryUtil';
import { getOperatingSystem, isMobile } from '../../../../../utils/Util';

import { isOpenNow } from '../../ScheduleUtils';
import { useCheckStock, useModal } from '../../../../../hooks';
import { useOrderRequest } from '../../hooks/useOrderRequest';
import { useEasypayPayment } from '../../hooks/useEasypayPayment';

import { FormHelperText, Separator } from '../../../../../common-styles';
import { CloseButton, ErrorMessage, ResumeOrderContainer } from './styles';
import { CLIENT_INFO_DATA, LocalStorageService, PAYMENT_EASYPAY } from '../../../../../services/LocalStorageService';
import { useOrderSendMessage } from '../../hooks/useOrderSendMessage';
import { prepareOrderToSend } from '../../../../../utils/PrepareOrderToSendUtil';
import { usePaymentProcess } from '../../../../../hooks/usePaymentProcess';


interface ResumeOrderDrawerProps {
  close(): void;
  companyFeatures: CompanyFeatures;
  companyName: string;
  handleDeleteAction(product: ProductMenu): void;
  handleEditAction(isUpdate: boolean, product: ProductMenu): void;
  isOpen: boolean;
  slug: string;
};

export const ResumeOrderDrawer = ({
  close: closeDrawer,
  companyName,
  companyFeatures,
  handleDeleteAction,
  handleEditAction,
  isOpen,
  slug,
}: ResumeOrderDrawerProps) => {
  const [order, setOrder] = useState({} as Order);
  const [userInfoData, setUserInfoData] = useState<Order | any>(null);
  const [deliveryFee, setDeliveryFee] = useState<DeliveryFee>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isClosedStore, setClosedStore] = useState(false);
  const [coupon, setCoupon] = useState<Coupon>();
  const [productsCannotBeSold, setProductsCannotBeSold] = useState<ProductsCannotBeSold>();

  const [paymentCardUrl, setPaymentCardUrl] = useState<string>();
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();

  const { toggle: toggleProductNotAvailable, isOpen: isOpenProductNotAvailable } = useModal();

  const { sellsConfiguration, isEasypayEnabled } = companyFeatures;
  const { currency, extraFees } = sellsConfiguration;

  const isMobileDevice = isMobile.any();

  const { checkStock } = useCheckStock(slug);
  const { menuConfiguration, isLoading: isLoadingOrderRequest, isRequestCompleted, executeOrderRequest } = useOrderRequest(slug);
  const { setOrder: setOrderPayment, isLoading: isLoadingOrderSendMessage } = useOrderSendMessage({ companySlug: slug, menuConfiguration });
  const { submitPayment: submitPaymentEasypay } = useEasypayPayment();
  const { isWaitingPayment, setIsWaitingPayment } = usePaymentProcess((isSuccess: boolean, paymentPayload: EasypayPaymentResponse | null) => {
    if (isSuccess) {
      
      const orderCloned = {
        ...order,
        coupon: undefined,
        observation: '',
        scheduledTime: '',
        scheduledDate: '',
      };
      
      LocalStorageService.save(`${CLIENT_INFO_DATA}-${companyName}`, orderCloned);
      setOrderPayment(paymentPayload?.order);
      ProductStore.clearProducts();
    } else {
      const isMbway = order.paymentType === MBWAY_PAYMENT_TYPE;
      const message = isMbway ? 'verifique se o seu número está correto, ou se o pagamento foi validado na app do MBway.' :
        'verifique se o seu cartão está correto, ou se o pagamento foi rejeitado na app do seu banco.';
      setPaymentErrorMessage('Ocorreu um erro ao processar o pagamento, ' + message);
      LocalStorageService.clear(PAYMENT_EASYPAY);
    }

    closeDrawer();
  });

  const isLoading = isLoadingOrderRequest || isLoadingOrderSendMessage;
  const formRef1 = createRef<HTMLFormElement>();
  const formRef2 = createRef<HTMLFormElement>();

  const products = toJS(ProductStore.products);

  const onStockValidation = useCallback((callbackResult: () => void) => {
    checkStock(
      ProductStore.products.map(product => {
        return {
          id: product._id,
          quantity: product.counter,
        }
      }), {
      onSuccess: (result: ProductOutOfStockResponse) => {
        if (!result.notAvailable || !result.withoutStock) {
          closeDrawer();
          setProductsCannotBeSold(result.data);
          toggleProductNotAvailable();
          return;
        }

        callbackResult();
      },
      onError: () => {
        NotificationDispatcher({
          message: 'Erro ao tentar verificar a disponibilidade dos produtos.',
          type: NotificationType.ERROR,
        });
      },
    });
  }, [closeDrawer, checkStock, toggleProductNotAvailable]);

  useEffect(() => {
    if (!isOpen) return;

    const checkStoreOpened = () => {
      if (!companyFeatures.isEstablishmentOpen) {
        return false;
      }

      return isOpenNow(companyFeatures.openingHours);
    }

    if (!checkStoreOpened()) {
      setClosedStore(true);
      return;
    }

    const checkUserInfo = () => {
      const userInfoData = LocalStorageService.get<Order>(`${CLIENT_INFO_DATA}-${companyName}`);

      if (userInfoData) {
        confirmAlert({
          title: 'Atenção',
          message: `Identificamos os dados de ${userInfoData.name}, deseja utilizar os mesmos dados?`,
          buttons: [
            {
              label: 'Sim',
              onClick: () => {
                setUserInfoData(userInfoData);
                setOrder(userInfoData);
                setDeliveryFee(userInfoData.clientFare);
              },
            },
            {
              label: 'Não',
              onClick: () => { },
            },
          ],
        });
      }
    };

    onStockValidation(checkUserInfo);
  }, [companyFeatures.isEstablishmentOpen, companyFeatures.openingHours, companyName, isOpen, onStockValidation]);

  useEffect(() => {
    if (isRequestCompleted) {
      closeDrawer();
    }
  }, [isRequestCompleted, closeDrawer]);

  const showMinOrderValueDialog = () => {
    const minOrderPrice = sellsConfiguration?.minOrderPrice ?? 0;
    confirmAlert({
      title: 'Atenção!',
      message: `O valor do pedido mínimo sem frete é de ${formattedTotalPrice(
        currency,
        minOrderPrice
      )}.`,
      buttons: [
        {
          label: 'Ok',
          onClick: () => { },
        },
      ],
    });
  }

  const handleOrderData = (id: string, value: any) => {
    if (id.includes('.')) {
      const [prefix, key, key2] = id.split('.');

      let { taxData } = order;
      if (!taxData || taxData === undefined) {
        taxData = {} as TaxData;
      }

      if (key2) {
        let { fiscalAddress } = taxData;
        if (!fiscalAddress || fiscalAddress === undefined) {
          fiscalAddress = {} as Address;
        }
        fiscalAddress[key2] = value;
        taxData.fiscalAddress = fiscalAddress;
      } else {
        taxData[key] = value;
      }

      setOrder({
        ...order,
        [prefix]: {
          ...taxData,
        },
      });
    } else {
      setOrder({
        ...order,
        [id]: value,
      });
    }
  }

  const handleDeliveryFeeChange = (deliveryFee?: DeliveryFee) => setDeliveryFee(deliveryFee);

  const isValidUsername = () => !order.name || order.name === undefined || order.name.trim().length === 0;

  const handleOrderSubmission = () => {
    if (
      !formRef1?.current?.reportValidity() ||
      !formRef2?.current?.reportValidity()
    ) {
      return;
    }

    if (isValidUsername()) {
      setErrorMessage('O campo nome é obrigatório');
      return;
    }

    if (sellsConfiguration.isTelephoneRequired && !order.phone) {
      setErrorMessage('O campo telefone é obrigatório');
      return;
    }

    if (sellsConfiguration?.isDatetimeForDeliveryOrPickupVisible && sellsConfiguration?.isTimePickerForDeliveryOrPickupVisible
      && sellsConfiguration?.isDatePickerRequired && !order.scheduledDate && sellsConfiguration?.isTimePickerRequired && !order.scheduledTime) {
      setUserInfoData({ ...order, datePickerError: true, timePickerError: true });
      setErrorMessage('Os campos data/hora do pré-agendamento, são obrigatórios');
      return;
    }

    if (sellsConfiguration?.isTimePickerForDeliveryOrPickupVisible && sellsConfiguration?.isTimePickerRequired && !order.scheduledTime) {
      setUserInfoData({ ...order, timePickerError: true });
      setErrorMessage('O campo hora do pré-agendamento é obrigatório');
      return;
    }

    if (sellsConfiguration?.isDatetimeForDeliveryOrPickupVisible && sellsConfiguration?.isDatePickerRequired && !order.scheduledDate) {
      setUserInfoData({ ...order, timePickerError: true });
      setErrorMessage('O campo data do pré-agendamento é obrigatório');
      return;
    }

    if (order.deliveryType === DeliveryType.DELIVERY && companyFeatures.location?.radius && !order.customerAddressInfo?.isAddressAvailable) {
      setErrorMessage('O seu endereço não está disponível para entrega.');
      return;
    }

    setUserInfoData({ ...order, datePickerError: false, timePickerError: false });
    setErrorMessage('');

    order.products = products;

    if (coupon) {
      order.coupon = coupon;
    }

    if (order.deliveryType === DeliveryType.TAKE_AWAY || order.deliveryType === DeliveryType.EAT_IN_LOCO) {
      order.clientFare = null;
      order.address = '';
    } else {
      if (order.canUseSameAddress) {
        if (!order.taxData || order.taxData === undefined) {
          order.taxData = {} as TaxData;
        }
        order.taxData.fiscalAddress = {
          address: order.address,
          number: order.number,
          postalCode: order.postalCode,
        } as Address;
      }

      if (order.deliveryType === undefined) {
        order.deliveryType = sellsConfiguration.hasDelivery ? DeliveryType.DELIVERY : DeliveryType.TAKE_AWAY;
      }
    }

    const { subtotal, total } = getTotalAndSubtotal(order, extraFees);

    const minOrderPrice = sellsConfiguration.minOrderPrice;
    if (
      order.deliveryType === DeliveryType.DELIVERY &&
      subtotal < minOrderPrice
    ) {
      showMinOrderValueDialog();
      return;
    }

    if (order.paymentType !== CASH_MONEY) {
      order.moneyChange = '';
    } else {
      if (order.moneyChange && Number(order.moneyChange) <= total) {
        setErrorMessage('O troco deve ser maior que o valor da compra.');
        return;
      }
    }

    onStockValidation(() => {
      const isMbway = order.paymentType.toUpperCase() === MBWAY_PAYMENT_TYPE;

      if (isEasypayEnabled && menuConfiguration && (isMbway || CARD_PAYMENT_TYPE.includes(order.paymentType.toUpperCase()))) {

        const isDesktop = getOperatingSystem() === 'Desktop';

        setIsWaitingPayment(isDesktop || isMbway);

        const orderBody = prepareOrderToSend({
          companyName,
          menuConfiguration,
          order,
        });

        submitPaymentEasypay({
          amount: total,
          customer: {
            name: order.name,
            email: order.email,
            phone: order.phone.substring(4),
            phone_indicative: order.phone.substring(0, 4),
            fiscal_number: order.taxData?.identification && `PT${order.taxData?.identification}`,
            key: 'final-customer',
            language: 'PT',
          },
          deliveryType: order.deliveryType,
          method: isMbway ? 'mbw' : 'cc',
          slug,
          order: orderBody,
        }, {
          onSuccess: (data: EasypayPaymentResponse) => {
            const paymentUrl = data.method.url;
            if (paymentUrl) {
              if (isDesktop) {
                window.open(paymentUrl, '_blank');
              } else {
                setPaymentCardUrl(paymentUrl);
              }
            }
          },
          onError: () => {
            setIsWaitingPayment(false);
            setPaymentErrorMessage('Ocorreu um erro ao processar o pagamento, por favor tente novamente.');
          },
        });
      } else {
        executeOrderRequest(order);
      }
    });
  }

  return (
    <>
      {paymentCardUrl && (
        <PaymentCardIframe
          url={paymentCardUrl}
          onClose={() => {
            setPaymentCardUrl(undefined);
            setIsWaitingPayment(true);
          }} />
      )}

      {paymentErrorMessage && (
        <ErrorDialog
          title='Falha no pagamento'
          error={paymentErrorMessage}
          onClose={() => { setPaymentErrorMessage(undefined) }} />
      )}

      <PaymentLoadingModal isOpen={isWaitingPayment}>
        {
          order.paymentType?.toUpperCase() === MBWAY_PAYMENT_TYPE ? (
            <>
              <h3>Pagamento a ser processado.</h3>
              <h4 style={{ textAlign: 'center' }}>Deverá finalizar o pagamento no MBWay. Após o pagamento, retorne a este ecrã. Por favor, não feche esta página.</h4>
            </>
          ) : (
            <h3>Estamos a processar o seu pagamento, por favor aguarde e não feche a página.</h3>
          )
        }
      </PaymentLoadingModal>

      {productsCannotBeSold && (
        <ProductsNotAvailableModal
          isOpen={isOpenProductNotAvailable}
          close={toggleProductNotAvailable}
          productsCannotBeSold={productsCannotBeSold} />
      )}

      <Drawer
        destroyOnClose={true}
        height={isMobileDevice ? '100%' : undefined}
        width={isMobileDevice ? '100%' : '500px'}
        maskClosable={false}
        onClose={closeDrawer}
        placement={isMobileDevice ? 'bottom' : 'right'}
        title={
          <h2 style={{ marginBottom: 0, lineHeight: '1.1', color: '#1A1919' }}>Resumo do pedido</h2>
        }
        open={isOpen}
        closeIcon={null}
        extra={
          <CloseButton
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeDrawer}
          >
            <span aria-hidden={true} style={{ color: '#1A1919' }}>
              ×
            </span>
          </CloseButton>
        }
      >
        <ResumeOrderContainer>
          <ClientOrderInformation
            companyFeatures={companyFeatures}
            formRef={formRef1}
            handleDeliveryFeeChange={handleDeliveryFeeChange}
            handleOrderData={handleOrderData}
            userInfoData={userInfoData}
            slug={slug}
          />

          {sellsConfiguration.showCouponFeature && (
            <CouponInput
              slug={slug}
              phone={order.phone}
              handleCouponCode={(couponResponse: Coupon) =>
                setCoupon(couponResponse)
              }
            />
          )}

          <BoxWithLabel title="Produtos">
            <CurrentOrder
              products={products}
              deliveryFee={deliveryFee}
              coupon={coupon}
              sellsConfiguration={sellsConfiguration}
              handleDeleteAction={handleDeleteAction}
              handleEditAction={handleEditAction}
            />
          </BoxWithLabel>

          {sellsConfiguration.paymentMethods.length > 0 && (
            <PaymentWay
              formRef={formRef2}
              paymentMethods={sellsConfiguration.paymentMethods}
              handleOrderData={handleOrderData}
              userInfoData={userInfoData}
            />
          )}

          <TextField
            id="observation"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="Observações gerais"
            multiline
            rows={4}
            inputProps={{ maxLength: 300 }}
            onChange={(e) => handleOrderData('observation', e.target.value)}
            style={{ margin: '15px 8px', width: '100%' }}
          />

          <Separator />

          <div style={{ marginTop: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
            <ErrorMessage>{errorMessage}</ErrorMessage>

            <Button
              loading={isLoading || isWaitingPayment}
              onClick={handleOrderSubmission}
              style={{ marginTop: '16px', fontWeight: 'bold' }}
              type='primary'
            >
              Finalizar pedido
            </Button>

            <FormHelperText style={{ textAlign: 'center', marginTop: '20px', fontSize: '12px' }}>
              Ao clicar em 'Finalizar pedido', você será redirecionado para o Whatsapp.
            </FormHelperText>
          </div>

          {isClosedStore && (
            <ClosedStore
              companyFeatures={companyFeatures}
              dismissPage={() => setClosedStore(false)}
            />
          )}
        </ResumeOrderContainer>
      </Drawer>
    </>
  );
};
