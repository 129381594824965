import styled from 'styled-components';

interface ContainerProps {
  color?: string;
}

export const Container = styled.div<ContainerProps>`
  cursor: pointer;

  display: flex;
  align-items: baseline;
  margin-left: 10px;

  > i {
    color: ${(props) => props.color ?? 'black'};
  }
  
  > p {
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.color ?? 'black'};
    margin-left: 2px;
  }
`;
