import styled from 'styled-components';

interface ProductComplementItemOptionProps {
  showTitle: boolean;
}

export const ResumeOrderContainer = styled.div`
  margin: unset;
  padding: unset;
  justify-content: start;
`;

export const ProductRowContainer = styled.div`
  text-align: left;
`;

export const RadioContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClientFareFooterContent = styled.div`
  display: flex;
  justify-content: space-between;

  #moreDetails {
    cursor: pointer;
    text-decoration: underline;
    color: #6c6c80;
    font-weight: 500;
    font-size: 14px;
  }
`;

// ProductOrderItem
export const ProductOrderItemContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: initial;
`;

export const ProductTitle = styled.span`
  color: #3f3e3e;
  font-size: 16px;
  text-align: left !important;
`;

export const ProductDescription = styled.div`
  color: #717171;
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  margin-top: 4px;

  > h3 {
    color: #717171;
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    margin-top: 4px;
  }
`;

export const ProductComplementTitle = styled.p`
  color: #3f3e3e;
  font-size: 14px;
  font-weight: bold;
  text-align: left !important;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const ProductComplementItemOption = styled.li<ProductComplementItemOptionProps>`
  margin-left: ${(props) => (props.showTitle ? '20px' : '0')};
`;

export const ProductComplements = styled.div`
  color: #717171;
  font-size: 14px;
  text-align: start;
  margin-left: 16px;

  > h3 {
    color: #717171;
    font-size: 14px;
    text-align: start;
    margin-top: 4px;
  }
`;

export const ProductPrice = styled.span`
  color: #3f3e3e;
  font-size: 16px;
  font-weight: 500;
  text-align: end;
`;

export const LabelButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const RemoveLabel = styled.span`
  color: #ea1d2c;
  font-size: 16px;
  font-weight: 500;

  cursor: pointer;

  margin-left: 16px;
`;

export const EditLabel = styled.span`
  color: #a6a29f;
  font-size: 16px;
  font-weight: 500;

  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const IdentificationErrorMessage = styled.p`
  color: red;
  margin-top: -10px;
  margin-bottom: 8px;
  margin-left: 4px;
  text-align: start;
`;

export const CloseButton = styled.button`
  .close > span:not(.sr-only) {
  width: 1.25rem;
  font-size: 35px;
  display: block;
  transition: all 0.15s ease;
  }
`;

export const SummaryLabel = styled.p`
  font-size: 22px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 15px;
  color: #3f3e3e;
  text-align: center;
`;