import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';

import * as Sentry from '@sentry/browser';
import { confirmAlert } from 'react-confirm-alert';

import { observer } from 'mobx-react';
import { ProductStore } from '../../../hooks/ProductStore/productsStore';
import { useBootClientSetup, useModal } from '../../../hooks';

import MenuController from './MenuController';
import { logEstablishmentEvent } from '../../../services/firebase';

import { AboutDetails } from './components/AboutDetails';
import { FeedbackRateButton } from '../../../components/FeedbackRateButton';
import { Footer } from '../../../components/Footer';
import { InformativeOfSellsPopUp } from '../../../components/InformativeOfSellsPopUp';
import { MainContent } from './components/MainContent';
import { NoConnection } from '../../../components/NoConnection';
import { OrderProductDetail } from './components/OrderProductDetail';
import { PageNotFound } from '../../../components/PageNotFound';
import { TabOptions } from './components/TabOptions';
import { ModalImage } from '../../../components/ModalImage';
import { ResumeOrderDrawer } from './components/OrdersViaWhatsapp/ResumeOrderDrawer';
import { LoaderSpinner } from '../../../components/LoaderSpinner';

import { MenuTabOption } from '../../../models/DataResponse';
import { ProductCatalog, ProductMenu } from '../../../models/ProductCatalog';
import { isMobile, removeAccents } from '../../../utils/Util';

import {
  MainContainer,
  CoverContent,
  LogoContent,
  SeeMoreButton,
  ScheduleAndFeedbackContainer,
} from './styles';
import './styles.css'; // FIXME: To be removed
import { HeaderMetadata } from '../../../components/HeaderMetadata';
import { useCheckPendingPaymentProcess } from './hooks/useCheckPendingPaymentProcess';
import { PaymentLoadingModal } from '../../../components/PaymentLoadingModal';
import { useClientProducts } from '../../../hooks/useClientProducts';
import { FeedbackDrawer } from './components/FeedbackDrawer';
import { CheckoutOrderButton } from './components/OrdersViaWhatsapp/components/CheckoutOrderButton';
import { OrderSummary } from './components/OrdersViaWhatsapp/components/OrderSummary';

const menuController = new MenuController();

export const DynamicMenu = observer(() => {
  const [menuNotFound, setMenuNotFound] = useState(false);
  const [showingProductDetail, setShowingProductDetail] = useState(false);
  const [alreadyShownSellsViaWhatsapp, setAlreadyShownSellsViaWhatsapp] = useState(false);
  const [isProductUpdate, setProductUpdate] = useState(false);
  const [data, setData] = useState<ProductCatalog[]>([]);
  const [dataFiltered, setDataFiltered] = useState<ProductCatalog[]>([]);
  const [menuOptions, setMenuOptions] = useState<MenuTabOption[]>([]);
  const [selectedMenuOption, setSelectedMenuOption] = useState<MenuTabOption | null>(null);

  const [selectedMenuItem, setSelectedMenuItem] = useState<ProductMenu | any>(null);
  const [isAboutDetailsOpen, setAboutDetailsOpen] = useState(false);
  const { isOpen: isOpenFeedbackDrawer, close: closeFeedbackDrawer, toggle: toggleFeedbackDrawer } = useModal();

  const { close: closeOrderSummary, isOpen: isOpenOrderSummary, open: openOrderSummary } = useModal();

  const companySlug = useParams().menu;
  const { hash: selectedTab } = useLocation();

  const { tabOptions, company, configuration, error, isCountByProduct, isError, isFetching: isFetchingBoot, refetch } = useBootClientSetup(companySlug);
  const { products, isFetching: isFetchingProducts } = useClientProducts(company?._id);
  const isFetching = isFetchingBoot || isFetchingProducts;

  const { hasPaymentPending } = useCheckPendingPaymentProcess(companySlug, configuration);
  const isMobileDevice = isMobile.any();

  useEffect(() => {
    if (!products || !company) return;
    if (selectedMenuOption && removeAccents(selectedTab).toLocaleLowerCase() === selectedMenuOption?.name.toLocaleLowerCase()) return;

    if (
      configuration?.menuOptionsEnabled &&
      (tabOptions?.length && tabOptions?.length > 0)
    ) {
      let menuOptionFilter = tabOptions;

      menuOptionFilter = menuController.filterMenuOptionsByConfig(
        configuration.menuOptionsShouldBeHidden,
        menuOptionFilter
      );

      if (menuOptionFilter.length === 0) {
        // None Tab is available for the current day
        setMenuNotFound(true);
        return;
      }

      const menuOption = menuController.getSelectedMenuOption(
        menuOptionFilter,
        selectedTab
      );
      setMenuOptions(menuOptionFilter);
      setSelectedMenuOption(menuOption);

      const filteredData = menuController.doFilterMenu(
        menuOption,
        products
      );

      setDataFiltered(filteredData);
    } else {
      setDataFiltered(products);
    }
    setData(products);

    if (menuController.canLogInAnalyticsFirebase()) {
      logEstablishmentEvent(company.slug);
    }
  }, [tabOptions, company, configuration, products, selectedTab, selectedMenuOption]);

  const handleChangeMenuOption = (selectedOption: MenuTabOption) => {
    setSelectedMenuOption(selectedOption);

    const filteredData = menuController.doFilterMenu(selectedOption, data);
    setDataFiltered(filteredData);
  };

  const addProductInBag = () => {
    if (!configuration?.sellViaWhatsappEnabled) return;

    setSelectedMenuItem(null);
  }

  const handleItemClick = (isUpdate: boolean, product: ProductMenu) => {
    if (!isUpdate) {
      product.counter = 1;
    }
    setProductUpdate(isUpdate);
    setSelectedMenuItem(product);
    setShowingProductDetail(true);
  }

  const handleRemoveOrderItem = (product: ProductMenu) => {
    ProductStore.removeProduct(product.transientId);
    setSelectedMenuItem(null);
  }

  const showNotificationPopUp = () => {
    return (
      configuration?.companyFeatures?.notificationPopUp?.isEnabled &&
      configuration?.companyFeatures.notificationPopUp.content
    );
  };

  const handleAlreadyShowPopSellsViaWhatapp = () => {
    setAlreadyShownSellsViaWhatsapp(true);
  };

  const shouldShowTitle = useMemo(() => {
    if (!configuration?.menuOptionsEnabled) {
      return true;
    }

    return dataFiltered.length > 1;
  }, [configuration, dataFiltered]);

  const canShowPopupSellsViaWhatsApp = useMemo(() => {
    // There's no SellsVia Whatsapp Enabled or
    // It was disabled by the Company
    if (
      !configuration?.sellViaWhatsappEnabled ||
      !configuration?.companyFeatures?.showInformeSellsViaWhatsapp
    ) {
      return false;
    }

    if (selectedMenuOption && configuration.menuOptionsEnabled) {
      return (
        selectedMenuOption.isSellViaWhatsappEnabled &&
        !alreadyShownSellsViaWhatsapp
      );
    } else {
      if (!alreadyShownSellsViaWhatsapp) {
        return true;
      }

      return false;
    }
  }, [configuration, selectedMenuOption, alreadyShownSellsViaWhatsapp]);

  function canRenderResumeOrder() {
    const hasSellViaWhatsappEnabled = configuration?.sellViaWhatsappEnabled;
    const hasProducts = ProductStore.products.length > 0;

    // For the case that we have Tabs available, we will check for both cases:
    // With or without Whatsapp.
    if (selectedMenuOption) {
      return hasSellViaWhatsappEnabled &&
        selectedMenuOption.isSellViaWhatsappEnabled
        ? hasProducts
        : false;
    }

    // If there's no TABs, we will check for the traditional menus, if it has WhatsApp enabled or not.
    return hasSellViaWhatsappEnabled && hasProducts;
  }

  if (isError) {
    const axiosError = error as AxiosError;
    if (axiosError.message === 'Network Error') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return <NoConnection onClose={onClose} onRetry={() => refetch()} />;
        }
      });
      return null;
    } else {
      Sentry.captureMessage('Not found menu for ' + companySlug);
      return <PageNotFound message="Nenhum cardápio/catálogo disponível neste momento" extra={<small><strong>slug: {companySlug}</strong></small>} />
    }
  }

  if (isFetching || !companySlug || !configuration || !company) {
    return <LoaderSpinner />;
  }

  const renderMainContent = () => {
    return (
      <>
        <HeaderMetadata title={company?.fantasyName} facebookPixelId={configuration.companyFeatures.facebookPixel} />

        <div id="page-menuonline">
          <div className="content-menuonline">
            {showNotificationPopUp() && (
              <ModalImage
                content={configuration.companyFeatures?.notificationPopUp?.content ?? ''}
              />
            )}

            {canShowPopupSellsViaWhatsApp && (
              <InformativeOfSellsPopUp shownPopup={handleAlreadyShowPopSellsViaWhatapp} />
            )}

            <MainContainer>
              {company?.coverUrl && (
                <div className="header">
                  <CoverContent
                    src={company.coverUrl}
                    alt="Cover"
                    dimens={company.styles?.coverDimens}
                  />
                </div>
              )}

              <div className="menuonline-header-info">
                {company?.logoUrl && (
                  <LogoContent
                    src={company.logoUrl}
                    alt="menuonline"
                    dimens={company.styles?.logoDimens}
                  />
                )}
              </div>

              <ScheduleAndFeedbackContainer>
                <FeedbackRateButton
                  customColor={menuController.getTitleColor(company)}
                  feedbackConfig={configuration.companyFeatures.feedback}
                  onClick={toggleFeedbackDrawer} />

                {configuration.companyFeatures?.openingHours !== undefined &&
                  configuration.companyFeatures?.openingHours?.length > 0 && (
                    <>
                      <SeeMoreButton
                        color={menuController.getTitleColor(company)}
                        onClick={() => setAboutDetailsOpen(true)}
                      >
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          style={{ marginRight: '2px' }}
                        />{' '}
                        Horário
                      </SeeMoreButton>
                    </>
                  )}
              </ScheduleAndFeedbackContainer>

              {(selectedMenuOption && menuOptions.length > 1) && (
                <TabOptions
                  menuOptions={menuOptions}
                  selectedMenuOption={selectedMenuOption}
                  labelColor={menuController.getTitleColor(company)}
                  handleChangeMenuOption={handleChangeMenuOption}
                />
              )}

              {isAboutDetailsOpen && (
                <AboutDetails
                  openingHours={configuration.companyFeatures?.openingHours ?? []}
                  dismissPage={() => setAboutDetailsOpen(false)}
                />
              )}

              {(showingProductDetail && selectedMenuItem) && (
                <OrderProductDetail
                  closePopUp={() => {
                    setSelectedMenuItem(null);
                    setShowingProductDetail(false);
                  }}
                  configuration={configuration}
                  handleProductAddition={addProductInBag}
                  isUpdate={isProductUpdate}
                  product={selectedMenuItem}
                  selectedMenuOption={selectedMenuOption}
                  slug={companySlug}
                />
              )}

              <MainContent
                configuration={configuration}
                data={dataFiltered}
                handleItemClick={handleItemClick}
                layoutType={company?.layout.type}
                selectedMenuOption={selectedMenuOption}
                shouldShowTitle={shouldShowTitle}
                subTitleColor={menuController.getSubTitleColor(company)}
                titleColor={menuController.getTitleColor(company)}
                tabsAvailable={selectedMenuOption != null && menuOptions.length > 1}
              />

              {canRenderResumeOrder() && !isMobileDevice && (
                <OrderSummary
                  isCountByProduct={isCountByProduct}
                  handleDeleteAction={handleRemoveOrderItem}
                  handleEditAction={handleItemClick}
                  sellsConfiguration={configuration.companyFeatures.sellsConfiguration}
                  onCheckout={() => openOrderSummary()}
                />
              )}

              <PaymentLoadingModal isOpen={Boolean(hasPaymentPending)}>
                <h3>Aguarde o pagamento ser processado, por favor não feche a página.</h3>
              </PaymentLoadingModal>
            </MainContainer>

            <Footer
              instagram={company?.instagram}
              facebook={company?.facebook}
              whatsapp={company?.whatsapp}
              phone={company?.phoneFooter}
              website={company?.websiteFooter}
            />
          </div>
        </div>

        {canRenderResumeOrder() && (
          <>
            {isMobileDevice && !isOpenOrderSummary && (
              <CheckoutOrderButton
                isCountByProduct={isCountByProduct}
                currency={configuration.companyFeatures.sellsConfiguration.currency}
                onClick={() => openOrderSummary()}
              />
            )}

            <ResumeOrderDrawer
              companyName={configuration.companyName}
              companyFeatures={configuration.companyFeatures}
              slug={companySlug ?? ''}
              handleDeleteAction={handleRemoveOrderItem}
              handleEditAction={handleItemClick}
              close={closeOrderSummary}
              isOpen={isOpenOrderSummary}
            />
          </>
        )}

        <FeedbackDrawer
          isOpen={isOpenFeedbackDrawer}
          items={configuration.companyFeatures.feedback.items}
          onClose={closeFeedbackDrawer}
          slug={companySlug}
        />
      </>
    );
  };

  return (
    <div>
      {menuNotFound ? (
        <PageNotFound message="Nenhum cardápio/catálogo disponível neste momento" extra={<small><strong>slug: {companySlug}</strong></small>} />
      ) : (
        renderMainContent()
      )}
    </div>
  );
});
